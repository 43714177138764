import React, { Component } from 'react';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import SaleProduct from '../../SaleProduct/SaleProduct';
import { addToCartLink } from '../../SaleProduct/CreateAddToCartLink';

export default class SaleProducts extends Component {
    static propTypes = {
        saleProduct: PropTypes.instanceOf(SaleProduct).isRequired,
        promoCode: PropTypes.string,
        source: PropTypes.string.isRequired,
        showBestseller: PropTypes.bool,
        buttonText: PropTypes.string,
        showOldPrice: PropTypes.bool,
        showShortDescription: PropTypes.bool,
        descriptionFirst: PropTypes.bool,
    };

    static defaultProps = {
        showBestseller: true,
        promoCode: '',
        buttonText: '',
        showOldPrice: true,
        showShortDescription: false,
        descriptionFirst: false,
    };

    render() {
        const {
            saleProduct,
            promoCode,
            source,
            showBestseller,
            descriptionFirst,
        } = this.props;

        return (
            <>
                <Card
                    data-test="product"
                    className={`product product--${this.props.color}`}
                    // data-id={product.id}
                >
                    {promoCode === 'ostatniaszansa' &&
                    saleProduct.id === 271 ? (
                        <div className="product-tag">NAJCZĘSTSZY WYBÓR</div>
                    ) : (
                        ''
                    )}

                    {showBestseller && saleProduct.isBestseller ? (
                        <div className="product-bestseller">bestseller</div>
                    ) : (
                        ''
                    )}
                    <div className="card-header">
                        <span data-test="product-name" className="product-name">
                            <strong>
                                {this.props.showShortDescription
                                    ? saleProduct.shortDescription
                                    : saleProduct.name}
                            </strong>
                        </span>
                    </div>
                    <div className="card-body">
                        {this.props.showThumbnail ? (
                            <img
                                src={saleProduct.thumbnailUrl}
                                className="img-fluid"
                                alt={saleProduct.name}
                            />
                        ) : (
                            ''
                        )}

                        {this.props.showOldPrice &&
                        saleProduct.hasActivePromo ? (
                            <React.Fragment>
                                <span
                                    data-test="product-description"
                                    className={`${
                                        this.props.color
                                            ? 'product-description product-description-' +
                                              this.props.color
                                            : 'product-description'
                                    } `}
                                >
                                    {saleProduct.lengthLabel}
                                </span>

                                <span
                                    data-test="product-old-price"
                                    className="product-old-price"
                                    data-testid="old-price"
                                >
                                    {`${saleProduct.regularPrice.toString()}`}
                                </span>

                                {!this.props.showShortDescription ? (
                                    ''
                                ) : (
                                    <span className="product-save">
                                        {`oszczędzasz ${saleProduct.diffPrice.toString()}`}
                                    </span>
                                )}

                                <span
                                    data-test="product-price"
                                    className="product-price"
                                >
                                    {`${saleProduct.price.toString()}`}
                                </span>
                            </React.Fragment>
                        ) : descriptionFirst ? (
                            <>
                                <span
                                    data-test="product-description"
                                    className={`${
                                        this.props.color
                                            ? 'product-description product-description-' +
                                              this.props.color
                                            : 'product-description'
                                    } `}
                                >
                                    {saleProduct.lengthLabel}
                                </span>
                                <span
                                    data-test="product-price"
                                    className="product-price"
                                >
                                    {`${saleProduct.price.toString()}`}
                                </span>
                            </>
                        ) : (
                            <>
                                <span
                                    data-test="product-price"
                                    className="product-price"
                                >
                                    {`${saleProduct.price.toString()}`}
                                </span>
                                <span
                                    data-test="product-description"
                                    className="product-description"
                                >
                                    {saleProduct.lengthLabel}
                                </span>
                            </>
                        )}
                    </div>
                    <div className="card-footer">
                        <a
                            data-testid="add-to-cart"
                            href={addToCartLink(
                                saleProduct.id,
                                promoCode,
                                source
                            )}
                            className={`btn btn-${this.props.color}`}
                        >
                            {this.props.buttonText
                                ? this.props.buttonText
                                : saleProduct.hasActivePromo
                                ? 'Kup z rabatem'
                                : 'Kup'}
                        </a>
                    </div>
                </Card>
            </>
        );
    }
}
