import React, { useContext } from "react"
import SaleProductItem from "./SaleProductItem"
import SaleProductPlaceholderSection from "./SaleProductsPlaceholder"
import { SaleProductContext } from "../../SaleProduct/SaleProductContext"

const SaleProducts = (props) => {
    const { saleProducts, isLoading, promoCode, source } = useContext(
        SaleProductContext
    )

    const renderPlaceholder = () => {
        return (
            <>
                <SaleProductPlaceholderSection />
                <SaleProductPlaceholderSection />
                <SaleProductPlaceholderSection />
            </>
        )
    }

    const renderProducts = (products, promoCode, source) => {
        const saleProducts = products
        const bestsellersKeys = saleProducts.reduce((carry, product, key) => {
            if (product.isBestseller) {
                carry.push(key)
            }
            return carry
        }, [])
        if (bestsellersKeys.length > 1) {
            const bestBestsellerKey =
                bestsellersKeys[Math.ceil(bestsellersKeys.length / 2) - 1]
            saleProducts.forEach((product, key) => {
                product.isBestseller = key === bestBestsellerKey
            })
        }

        return saleProducts.map(saleProduct => (
            <SaleProductItem
            key={saleProduct.id}
            saleProduct={saleProduct}
            promoCode={promoCode}
            source={source}
            color={props.color}
            showThumbnail={props.showThumbnail}
            showShortDescription={props.showShortDescription}
            showBestseller={props.showBestseller}
            showOldPrice={props.showOldPrice}
            buttonText={props.buttonText}
            descriptionFirst={props.descriptionFirst}
        />
        ))
    }
    return (
        <>
            {isLoading
                ? renderPlaceholder()
                : renderProducts(saleProducts, promoCode, source)}
        </>
    )
}

export default SaleProducts
