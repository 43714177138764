import React, { useEffect } from 'react';
import SimplexNoise from 'simplex-noise';

function hsl(hue, sat, light, alpha = 1) {
    if (typeof hue !== 'number') {
        if (Array.isArray(hue)) {
            [hue, sat, light, alpha = alpha] = hue;
        } else if ('h' in hue) {
            ({ h: hue, s: sat, l: light, a: alpha = alpha } = hue);
        }
    }
    hue = hue % 360;
    if (hue < 0) {
        hue += 360;
    }
    return `hsl(${hue} ${sat}% ${light}% / ${alpha})`;
}

    const colors = {
        white: hsl(0, 0, 100, 0.3),
        pink: hsl(350, 100, 88, 0.3)
    }

const Waves = ({color = 'white'}) => {
    
    const canvasElement = React.createRef();
    

    useEffect(() => {
        
        const { PI } =  Math;
        const ONE = 1.0;
        const TWO = 2.0;
        const HALF = ONE / TWO;
        const TAU = PI * TWO;
  
        let _defaulCanvasOptions = {
            autoClear: false,
            autoCompensate: true,
            autoPushPop: false,
            canvas: true,
            centered: false,
            desynchronized: false,
            drawAndStop: false,
            width: null,
            height: null };
  
        let _canvasOptions = {};
        let canvas = canvasElement.current;

        let ctx = canvas.getContext('2d', {
            desynchronized: window.canvasOptions && window.canvasOptions.desynchronized !== undefined ?
            window.canvasOptions.desynchronized : _defaulCanvasOptions.desynchronized    
        });

        const _originalCtx = ctx;
        let _anim, _lastCanvasTime, canvasFrameRate, frameCount, width, height, width_half, height_half, width_quarter, height_quarter;
        let _canvasCurrentlyCentered = false;
  
        window.addEventListener('resize', _resizeCanvas);
        window.addEventListener('load', () => {
            Object.assign(
                _canvasOptions,
                _defaulCanvasOptions,
                'canvasOptions' in window ? window.canvasOptions : {}
            );
  
            _resizeCanvas();          
            frameCount = 0;
            _anim = requestAnimationFrame(_draw);
        });
  
        function compensateCanvas() {
            let offX = 0;
            let offY = 0;
            if(width % 2) offX += 0.5;
            if(height % 2) offY += 0.5;
            if(offX || offY) {
                translate(offX, offY);
            }
        }

        function translate(x = 0, y = 0) {
            if(typeof x === 'number') {
                ctx.translate(x, y);
            }
            else if('x' in x) {
                ctx.translate(x.x, x.y);
            }
        }

        function _draw(timestamp) {
            frameCount++;
            canvasFrameRate = 1000.0 / (timestamp - _lastCanvasTime);
            if (!_lastCanvasTime) {
                _lastCanvasTime = timestamp;
            }
            // _lastCanvasTime = timestamp;
            ctx = _originalCtx;
            _canvasOptions.autoClear && clear(null);
            if (_canvasOptions.autoPushPop) {
                push();
                _canvasOptions.centered && (_canvasCurrentlyCentered = true) && translateCenter();
                _canvasOptions.autoCompensate && compensateCanvas();
            }
           
            draw(timestamp);

            _canvasOptions.autoPushPop && pop();
            _canvasCurrentlyCentered = false;
            _lastCanvasTime = timestamp;
            if (_canvasOptions.drawAndStop) {
                return;
            }
            _anim = requestAnimationFrame(_draw);
        }
  
        function _resizeCanvas(specificCanvas) {
            width = canvas.width = _canvasOptions.width !== null ? _canvasOptions.width : window.innerWidth;
            height = canvas.height = _canvasOptions.height !== null ? _canvasOptions.height : window.innerHeight;
            width_quarter = (width_half = width * HALF) * HALF;
            height_quarter = (height_half = height * HALF) * HALF;
            ctx.fillStyle = 'hsl(0, 0%, 100%)';
            ctx.strokeStyle = 'hsl(0, 0%, 100%)';
            if ('onResize' in window) {
                window.onResize();
            }
        }
  
        function clear(x, y, w, h) {
            if (x !== undefined && typeof x === 'number') {
                ctx.clearRect(x, y, w, h);
            } else if (_canvasOptions.centered && _canvasCurrentlyCentered /*  && x !== null */) {
                ctx.clearRect(-width_half, -height_half, width, height);
            } else {
                ctx.clearRect(0, 0, width, height);
            }
        }
  
        function lineWidth(w) {
            if (typeof w === 'number') {
                ctx.lineWidth = w;
            }
            return ctx.lineWidth;
        }
  
        function strokeStyle(...args) {
            if (args.length === 1) {
                let [a] = args;
                if (typeof a === 'string' || a instanceof CanvasGradient) {
                    ctx.strokeStyle = a;
                }
            } else if (args.length === 2) {
                strokeStyle(args[0]);
                lineWidth(args[1]);
            }
            return ctx.strokeStyle;
        }
  
   
  
        function stroke(...args) {
            let path;
            if (args.length) {
                if (args[0] instanceof Path2D) {
                    path = args.shift();
                }
                strokeStyle(...args);
            }
            // Must branch the fill/stroke call as it
            // recognizes the undefined argument
            path ? ctx.stroke(path) : ctx.stroke();
        }
  
        function push() {
            ctx.save();
        }
  
        function pop() {
            ctx.restore();
        }
  
        function translateCenter(x = 0, y = 0) {
            ctx.translate(width_half + x, height_half + y);
        }
  
        function beginPath() {
            ctx.beginPath();
        }
  
        function moveTo(x, y) {
            if (typeof x === 'number') {
                ctx.moveTo(x, y);
            }
        }
  
        function lineTo(x, y) {
            if (typeof x === 'number') {
            ctx.lineTo(x, y);
            } 
        }
  
        function cos(input, mult = 1, add = 0) {
            return Math.cos(input % TAU) * mult;
        }

        window.canvasOptions = {
            autoClear: true,
            autoCompensate: false,
            autoPushPop: true,
            canvas: true,
            centered: true,
            width: null,
            height: null
        };

        let noise = new SimplexNoise();

        const draw = e => {
            let xCount = 35;
            let yCount = 40;
            let iXCount = 1 / (xCount - 1);
            let iYCount = 1 / (yCount - 1);
            let time = e * 0.0002;
            let timeStep = 0.01;
            ctx.globalAlpha = 1;
            beginPath();
            for (let j = 0; j < yCount; j++) {
            let tj = j * iYCount;
            let c = cos(tj * TAU + time) * 0.1;
            for (let i = 0; i < xCount; i++) {
                let t = i * iXCount;
                let n = noise.noise3D(t, time, c);
                let y = n * height_half;
                let x = t * (width + 20) - width_half - 10;
                (i ? lineTo : moveTo)(x, y);
            }
            time += timeStep;
            }

            stroke(colors[color], 1);
        }
    }, [canvasElement]);
  
    return (
        <canvas ref={canvasElement} />
    );
}

export default Waves;
